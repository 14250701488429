<template>
  <div class="d_flex">
    <v-overlay
      v-show="saleReceiptStatusRequest.value === 'loading-getSaleReceiptById'"
      opacity="0.5"
    >
      <v-progress-circular
        color="grey lighten-2"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <!-- Start: Filter area -->
    <div class="px-5">
      <filter-area
        :branches-filter="branchesFilter"
        :customer-filter="customerFilter"
        :date-filter="dateFilter"
        :debt-filter="debtFilter"
        :customer-receivables="customerReceivables"
        :employees-filter="employeesFilter"
        :site-filter="siteFilter"
        :status-filter="statusFilter"
        @updateFilterProperty="updateFilterProperty($event)"
      ></filter-area>
    </div>
    <!-- End: Filter area -->
    <!-- Start: Main area -->
    <div class="flex-grow-1 overflow-hidden">
      <!-- Start: Top actions -->
      <top-action
        :current-page="currentPage"
        :search-key="searchKey"
        :search-options="searchOptions"
        :search-type="searchType"
        :selected-show-filter-id="itemPerPage"
        @exportReportFile="exportReportFile"
        @updateCurrentPage="updateCurrentPage($event)"
        @updateItemPerPage="updateItemPerPage($event)"
        @updateSearchKey="updateSearchKey($event)"
      ></top-action>
      <!-- End: Top actions -->
      <!-- Start: Data table -->
      <div class="mr-5">
        <data-table-items></data-table-items>
      </div>
      <!-- End: Data table -->
    </div>
    <!-- End: Main area -->
    <!-- Start: Modal 3rd store -->
    <!-- End: Modal 3rd store -->
  </div>
</template>

<script>
import DataTableItems from "./components/DataTableItems";
import FilterArea from "./components/FilterArea";
import TopAction from "./components/TopAction";
import { exportExcelFile, updateFilterProperty } from "@/core/composables";

export default {
  components: {
    DataTableItems,
    FilterArea,
    TopAction
  },

  data() {
    return {
      branchesFilter: [],
      currentPage: 1,
      dateFilter: {
        value: null,
        type: 1
      },
      searchOptions: [
        { type: "customer_name", label: "Theo tên khách hàng" },
        { type: "customer_phone", label: "Theo SĐT khách hàng" },
        { type: "serial_number", label: "Theo Serials" },
        { type: "MPN", label: "Theo MPN" },
        { type: "SKU", label: "Theo SKU" },
        { type: "code", label: "Theo mã hoá đơn" },
        { type: "order_code", label: "Theo mã đơn hàng" }
      ],
      debtFilter: {
        from: null,
        to: null
      },
      customerReceivables: {
        from: null,
        to: null
      },
      searchType: 0,
      employeesFilter: [],
      itemPerPage: 50,
      searchKey: null,
      siteFilter: "ALL",
      statusFilter: [],
      customerFilter: null
    };
  },

  watch: {
    "$route.query"(val) {
      this.getSaleReceiptsFromRouteQuery(val);
    }
  },

  computed: {
    saleReceiptStatusRequest() {
      return this.$store.getters["SALE_RECEIPT/statusRequest"];
    }
  },

  async created() {
    const route = this.$route;

    await this.getSaleReceiptsFromRouteQuery(route.query);
  },

  methods: {
    async exportReportFile(type) {
      await exportExcelFile(this, {
        storeName: "SALE_RECEIPT",
        payload: {
          branchIds: this.branchesFilter,
          status: this.statusFilter,
          employeeIds: this.employeesFilter,
          fromDate: this.dateFilter.value ? this.dateFilter.value[0] : null,
          toDate: this.dateFilter.value ? this.dateFilter.value[1] : null,
          type: type
        },
        fileName: "bao-cao-ban-hang",
        isCheckExportTime: true
      });
    },

    async pushRouter() {
      await this.$router.push({
        name: "transaction_sale-receipts",
        query: {
          site: this.siteFilter,
          branch_id:
            this.branchesFilter && this.branchesFilter.length > 0
              ? this.branchesFilter
              : undefined,
          closing_user_id:
            this.employeesFilter && this.employeesFilter.length > 0
              ? this.employeesFilter
              : undefined,
          status:
            this.statusFilter && this.statusFilter !== "all"
              ? this.statusFilter
              : undefined,
          date_type:
            this.dateFilter.value &&
            this.dateFilter.value[0] &&
            this.dateFilter.type
              ? this.dateFilter.type
              : undefined,
          date_from:
            this.dateFilter && this.dateFilter.value
              ? this.dateFilter.value[0]
              : undefined,
          date_to:
            this.dateFilter && this.dateFilter.value
              ? this.dateFilter.value[1]
              : undefined,
          debt_customer_from:
            this.debtFilter.from !== null ? this.debtFilter.from : undefined,
          debt_customer_to:
            this.debtFilter.to !== null ? this.debtFilter.to : undefined,
          user_cong_no_from:
            this.customerReceivables.from !== null
              ? this.customerReceivables.from
              : undefined,
          user_cong_no_to:
            this.customerReceivables.to !== null
              ? this.customerReceivables.to
              : undefined,
          search:
            this.searchKey && this.searchKey.length > 0
              ? this.searchKey
              : undefined,
          search_type: this.searchType !== 0 ? this.searchType : undefined,
          limit: this.itemPerPage,
          page: this.currentPage
        }
      });
    },

    async getSaleReceiptsFromRouteQuery(query) {
      if (query.page) {
        // Set filtered branches
        this.branchesFilter =
          typeof query.branch_id === "string"
            ? [parseInt(query.branch_id)]
            : typeof query.branch_id === "object"
            ? query.branch_id.map(item => parseInt(item))
            : [];
        // Set current page
        this.currentPage = parseInt(query.page);
        // Set filtered date
        this.dateFilter = query.date_from
          ? {
              value: [query.date_from, query.date_to],
              type: parseInt(query.date_type)
            }
          : { value: null, type: 1 };
        // Set filtered debt
        this.debtFilter = {
          from:
            query.debt_customer_from !== undefined
              ? parseFloat(query.debt_customer_from)
              : null,
          to:
            query.debt_customer_to !== undefined
              ? parseFloat(query.debt_customer_to)
              : null
        };
        this.customerReceivables = {
          from:
            query.user_cong_no_from !== undefined
              ? parseFloat(query.user_cong_no_from)
              : null,
          to:
            query.user_cong_no_to !== undefined
              ? parseFloat(query.user_cong_no_to)
              : null
        };
        // Set filtered branches
        this.employeesFilter =
          typeof query.closing_user_id === "string"
            ? [parseInt(query.closing_user_id)]
            : typeof query.closing_user_id === "object"
            ? query.closing_user_id.map(item => parseInt(item))
            : [];
        // Set item per page
        this.itemPerPage = parseInt(query.limit);
        // Set search key
        this.searchKey = query.search || null;

        this.searchType = parseInt(query.search_type) || 0;

        this.siteFilter = query.site || "ALL";

        // Set filtered status
        this.statusFilter =
          typeof query.status === "string"
            ? [parseInt(query.status)]
            : typeof query.status === "object"
            ? query.status.map(item => parseInt(item))
            : [];

        // Get products
        await this.$store.dispatch("SALE_RECEIPT/getSaleReceipts", {
          filter: {
            site: this.siteFilter,
            branch_id: this.branchesFilter,
            closing_user_id: this.employeesFilter,
            status: this.statusFilter,
            fromDate:
              this.dateFilter && this.dateFilter.value
                ? this.dateFilter.value[0]
                : null,
            toDate:
              this.dateFilter && this.dateFilter.value
                ? this.dateFilter.value[1]
                : null,
            debt_customer_from: this.debtFilter.from,
            debt_customer_to: this.debtFilter.to,
            user_cong_no_from: this.customerReceivables.from,
            user_cong_no_to: this.customerReceivables.to
          },
          search: this.searchKey,
          search_key: this.searchOptions[this.searchType].type,
          per_page: this.itemPerPage,
          cur_page: this.currentPage
        });
      } else {
        // Push router query
        await this.pushRouter();
      }
    },

    getIndexOfSearchOption(option) {
      const types = this.searchOptions.map(item => item.type);

      return types.indexOf(option);
    },

    updateCurrentPage(page) {
      if (this.currentPage === page) return;
      this.currentPage = page;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateFilterProperty(data) {
      updateFilterProperty(this, data.filterProperty, data.value);
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateDebtFilter(val) {
      this.debtFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateItemPerPage(num) {
      if (this.itemPerPage === num) return;
      this.itemPerPage = num;
      this.currentPage = 1;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateSearchKey(val) {
      this.searchKey = val.searchKey;
      if (val.option) {
        this.searchType = this.getIndexOfSearchOption(val.option);
      }
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    }
  }
};
</script>

<style lang="scss" scoped></style>
