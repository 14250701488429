<template>
  <div class="action mr-5 mb-4">
    <!-- Start: Action row -->
    <div class="action-row d-flex align-center justify-space-between">
      <div class="action-row--col flex-grow-1 pr-5">
        <tp-search-option
          :default-option="searchType"
          :options="searchOptions"
          v-model="searchKey"
          @search="updateSearchKey($event)"
        ></tp-search-option>
      </div>
      <div class="action-row--col">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="ml-3 rounded-lg"
              color="primary"
              dark
              depressed
              :loading="
                saleReceiptStatusRequest.value === 'loading-exportReportFile'
              "
              outlined
            >
              <v-icon left>mdi-file-download-outline</v-icon> Xuất file
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in type" :key="index">
              <v-list-item-title @click="$emit('exportReportFile', item.type)">
                <button class="type-export">
                  {{ item.title }}
                </button>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn
          class="ml-3 rounded-lg"
          color="primary"
          depressed
          @click="gotoSalePage()"
        >
          <v-icon left>mdi-plus</v-icon> Thêm mới
        </v-btn>
      </div>
    </div>
    <!-- End: Action row -->
    <!-- Start: Action row -->
    <div class="action-row d-flex align-center justify-space-between mt-4">
      <div class="action-row--col align-center">
        <!-- Start: Show filter dropdown -->
        <tp-btn-overflow
          btn-class="white rounded-lg"
          :items="showFilterList"
          :selected-value="selectedShowFilterId"
          @change="selectedShowFilterIndex = $event"
          @select="selectShowFilter($event)"
        ></tp-btn-overflow>
        <!-- End: Show filter dropdown -->
      </div>
      <div class="action-row--col d-flex align-center">
        <div class="text-body-2">
          {{ paginationInfo.from }} - {{ paginationInfo.to }} trong số
          {{ paginationInfo.itemTotal }}
        </div>
        <v-pagination
          v-model="curPage"
          color="primary"
          :length="paginationInfo.pageTotal"
          :total-visible="5"
        ></v-pagination>
      </div>
    </div>
    <!-- End: Action row -->
  </div>
</template>

<script>
import { INIT_SALE_RECEIPT_VALUE } from "@/modules/Transaction/constant";

export default {
  props: {
    currentPage: {
      type: Number
    },
    selectedShowFilterId: {
      type: Number
    },
    searchKey: {
      type: String
    },
    searchType: {
      type: Number
    },
    searchOptions: {
      type: Array
    }
  },
  data() {
    return {
      actionList: [{ id: "delete", text: "Xóa" }],
      oldCmsUrl: process.env.OLD_CMS_APP_URL,
      showFilterList: [
        { id: 25, text: "Hiển thị 25" },
        { id: 50, text: "Hiển thị 50" },
        { id: 100, text: "Hiển thị 100" }
      ],
      type: [
        { title: "Mặc định", type: "DEFAULT" },
        { title: "HR", type: "HR  " },
        { title: "Kế toán", type: "ACCOUNTING" }
      ]
    };
  },
  computed: {
    curPage: {
      get() {
        return this.currentPage;
      },
      set(val) {
        this.$emit("updateCurrentPage", val);
      }
    },
    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    },
    paginationInfo() {
      return this.$store.getters["SALE_RECEIPT/paginationInfo"];
    },
    saleReceiptStatusRequest() {
      return this.$store.getters["SALE_RECEIPT/statusRequest"];
    },
    saleTabs() {
      return this.$store.getters["SALE/saleTabs"];
    },
    typeSaleTabs() {
      return this.$store.getters["SALE/typeSaleTabs"];
    }
  },
  methods: {
    async gotoSalePage() {
      const saleTabsSaleReceipts = this.saleTabs.filter(
        item => item.type === 1 && item.code.slice(0, 7) === "Hóa đơn"
      );

      await this.$store.dispatch("SALE/addSaleTabItem", {
        ...JSON.parse(JSON.stringify(INIT_SALE_RECEIPT_VALUE)),
        code: `Hóa đơn ${saleTabsSaleReceipts.length + 1}`,
        closing_user_id: this.user.role !== "admin" ? this.user.id : null,
        branch_id: this.user && this.user.branch_id ? this.user.branch_id : null
      });

      // Set active tab
      await this.$store.dispatch(
        "SALE/setSaleTabActiveIndex",
        this.saleTabs.length - 1
      );

      this.$store.dispatch("SALE_RECEIPT/resetSaleReceiptPaymentTransactions");

      // Push route
      await this.$router.push({ name: "sale" });
    },

    selectShowFilter(val) {
      this.$emit("updateItemPerPage", val.id);
    },

    updateSearchKey(val) {
      this.$emit("updateSearchKey", val);
    },

    async uploadExcelFile() {
      const excelFile = this.$refs.file.files;
      const formData = new FormData();
      const routeQuery = this.$route.query;

      Array.from(excelFile).forEach(f => {
        formData.append("file", f);
      });
      // Import request
      await this.$store.dispatch("THIRD_STORE/importExcel", {
        formData: formData,
        routeQuery: {
          filters: {
            supplier_id:
              typeof routeQuery.supplier_id === "string"
                ? [parseInt(routeQuery.supplier_id)]
                : typeof routeQuery.supplier_id === "object"
                ? routeQuery.supplier_id.map(item => parseInt(item))
                : null,
            stock_status: routeQuery.store_status
              ? parseInt(routeQuery.store_status)
              : null,
            fromPrice: parseInt(routeQuery.price_from),
            toPrice: parseInt(routeQuery.to)
          },
          search: routeQuery.search || null,
          per_page: parseInt(routeQuery.limit),
          cur_page: parseInt(routeQuery.page)
        }
      });
      // Check request status
      if (this.thirdStoreStatusRequest.value === "success-importExcel") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã nhập file thành công"
          }
        });
      }
      // Reset ref
      const input = this.$refs.file;
      input.type = "text";
      input.type = "file";
    }
  }
};
</script>

<style lang="scss" scoped>
.action {
  &-row {
    &--col {
      display: flex;
    }
  }
}
</style>
