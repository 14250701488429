<template>
  <div class="filter-area">
    <!-- <div class="font-weight-bold text-h5 mb-4">Phiếu xuất kho</div> -->
    <v-tabs v-model="activePage" class="mb-3" background-color="grey lighten-3">
      <v-tab
        class="font-weight-bold"
        href="#OrderPage"
        :to="{
          name: 'transaction_orders'
        }"
      >
        Đơn hàng
      </v-tab>

      <v-tab class="font-weight-bold" href="#SaleReceipt">Hoá đơn bán</v-tab>
    </v-tabs>

    <v-card class="tp-filter-scroll pa-5" flat>
      <div>
        <div class="font-weight-bold mb-2">
          Nguồn
        </div>
        <v-radio-group v-model="selectedSite" class="mt-0" dense hide-details>
          <v-radio label="Tất cả" value="ALL"></v-radio>
          <v-radio
            v-for="(site, index) in ARRAY_LIST_MODEL_SITES"
            :key="index"
            :label="site.text"
            :value="site.id"
          ></v-radio>
        </v-radio-group>
      </div>

      <div class="mt-5">
        <div class="font-weight-bold mb-2">Chi nhánh</div>
        <v-autocomplete
          v-model="selectedBranches"
          :items="branches"
          chips
          class="tp-filter-autocomplete"
          deletable-chips
          dense
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          label="Outlined"
          multiple
          no-data-text="Không có dữ liệu"
          outlined
          placeholder="Chọn chi nhánh"
          single-line
          small-chips
        ></v-autocomplete>
      </div>

      <div class="mt-5">
        <div class="font-weight-bold mb-2">Thời gian</div>
        <tp-input-time-filter
          :type="dateFilter.type"
          :value="dateFilter.value"
          @change="updateDateFilter($event)"
        ></tp-input-time-filter>
      </div>

      <div class="mt-5">
        <div class="font-weight-bold mb-2">Trạng thái</div>
        <v-checkbox
          v-for="item in status"
          :key="item.id"
          v-model="selectedStatus"
          :label="item.text"
          :value="item.id"
          class="mt-1"
          dense
          hide-details
        ></v-checkbox>
      </div>

      <!-- <div class="mt-5">
        <div class="font-weight-bold mb-2">Khách còn nợ</div>
        <v-radio-group v-model="selectedCustomer" class="mt-0" dense hide-details>
          <v-radio label="Tất cả" value="all"></v-radio>
          <v-radio label="Không" :value="1"></v-radio>
          <v-radio label="Còn nợ" :value="2"></v-radio>
        </v-radio-group>
      </div> -->

      <div class="mt-5">
        <div class="font-weight-bold mb-2">Người chốt đơn</div>
        <v-autocomplete
          v-model="selectedEmployees"
          :items="saleEmployees"
          chips
          class="tp-filter-autocomplete"
          deletable-chips
          dense
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          label="Outlined"
          multiple
          no-data-text="Không có dữ liệu"
          outlined
          placeholder="Chọn nhân viên"
          single-line
          small-chips
        ></v-autocomplete>
      </div>

      <div class="mt-5">
        <div class="font-weight-bold mb-2">Khách còn nợ</div>
        <tp-input-price-filter
          :items="debtValueList"
          :value="debtFilter"
          @change="
            val =>
              $emit('updateFilterProperty', {
                filterProperty: 'debtFilter',
                value: val
              })
          "
        ></tp-input-price-filter>
      </div>

      <div class="mt-5">
        <div class="font-weight-bold mb-2">Công nợ khách hàng</div>
        <tp-input-price-filter
          :items="customerReceivablesList"
          :value="customerReceivables"
          @change="
            val =>
              $emit('updateFilterProperty', {
                filterProperty: 'customerReceivables',
                value: val
              })
          "
        ></tp-input-price-filter>
      </div>
    </v-card>
  </div>
</template>

<script>
import { ARRAY_LIST_MODEL_SITES, LIST_MODEL_SITES } from "@/core/constant";

export default {
  props: {
    branchesFilter: {
      type: Array
    },
    dateFilter: {
      type: Object
    },
    debtFilter: {
      type: Object
    },
    customerReceivables: {
      type: Object
    },
    employeesFilter: {
      type: Array
    },
    siteFilter: {
      type: String
    },
    statusFilter: {
      type: Array,
      default: null
    }
  },

  computed: {
    branches() {
      return this.$store.getters["BRANCH/allBranches"];
    },
    employees() {
      return this.$store.getters["EMPLOYEE/allEmployees"];
    },
    saleEmployees() {
      return this.employees.filter(i => i.staff_roles.includes("SALES"));
    },
    selectedBranches: {
      get() {
        return this.branchesFilter;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "branchesFilter",
          value: val
        });
      }
    },
    selectedEmployees: {
      get() {
        return this.employeesFilter;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "employeesFilter",
          value: val
        });
      }
    },
    selectedSite: {
      get() {
        return this.siteFilter;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "siteFilter",
          value: val
        });
      }
    },
    selectedStatus: {
      get() {
        return this.statusFilter;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "statusFilter",
          value: val
        });
      }
    }
  },

  created() {
    this.$store.dispatch("BRANCH/getAllBranches");
    this.$store.dispatch("EMPLOYEE/getAllEmployees");
  },

  data() {
    return {
      activePage: "SaleReceipt",

      status: [
        {
          id: 1,
          text: "Thành công"
        },
        {
          id: -1,
          text: "Đã hủy"
        }
      ],

      debtValueList: [
        { id: 1, text: "Tất cả", value: { from: null, to: null } },
        { id: 2, text: "Bằng 0", value: { from: 0, to: 0 } },
        { id: 3, text: "Lớn hơn 0", value: { from: 0.000001, to: null } },
        { id: 4, text: "Nhỏ hơn 0", value: { from: null, to: -0.000001 } }
      ],
      customerReceivablesList: [
        { id: 1, text: "Tất cả", value: { from: null, to: null } },
        { id: 2, text: "Bằng 0", value: { from: 0, to: 0 } },
        { id: 3, text: "Lớn hơn 0", value: { from: 0.000001, to: null } },
        { id: 4, text: "Nhỏ hơn 0", value: { from: null, to: -0.000001 } }
      ],
      ARRAY_LIST_MODEL_SITES,
      LIST_MODEL_SITES
    };
  },

  methods: {
    updateDateFilter(val) {
      this.$emit("updateFilterProperty", {
        filterProperty: "dateFilter",
        value: val
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-area {
  width: 256px;
}
</style>
