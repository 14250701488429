<template>
  <v-data-table
    :headers="headers"
    :items="saleReceipts"
    :loading="saleReceiptStatusRequest.value === 'loading-getSaleReceipts'"
    calculate-widths
    class="tp-table-scroll tp-table__row-pointer datatable px-3 py-2"
    disable-pagination
    hide-default-footer
    item-key="id"
    loading-text="Đang tải dữ liệu"
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
  >
    <!-- Start: Body -->
    <template v-slot:body="{ items }">
      <tbody v-if="items.length > 0">
        <!-- Start: Total -->
        <tr class="yellow lighten-5">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="font-weight-bold">
            {{ saleReceiptListTotals.total_goods_value | formatCurrency }}
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <!-- Start: Total -->
        <!-- Start: Items -->
        <tr v-for="item in items" :key="item.id" @click="viewDetail(item)">
          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="copied-label"
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="copyToClipboard(item.code)"
                >
                  {{ item.code }}
                </div>
              </template>
              <span>Sao chép</span>
            </v-tooltip>
          </td>
          <td>{{ item.created_at }}</td>
          <td>
            <div>{{ item.khachhang_name }}</div>
            <div>{{ item.khachhang_phone || "N/A" }}</div>
          </td>
          <td>{{ item.khachhang_cong_no | formatCurrency }}</td>

          <td>
            {{ item.closing_user ? item.closing_user.name || "N/A" : "N/A" }}
          </td>
          <td>{{ item.branch_name }}</td>
          <td>{{ item.goods_value | formatCurrency }}</td>
          <td>{{ item.value | formatCurrency }}</td>
          <td>{{ item.khachhang_con_no | formatCurrency }}</td>
          <td class="text-center">
            <v-chip
              :color="SALE_RECEIPT_STATUS[item.status].color"
              class="font-weight-bold"
              outlined
              small
            >
              {{ SALE_RECEIPT_STATUS[item.status].label }}
            </v-chip>
          </td>

          <td class="text-center">
            <v-chip
              v-if="!item.order_cancel_status"
              :color="getOrderStatus(item.order_status).color"
              class="font-weight-bold"
              outlined
              small
            >
              {{ getOrderStatus(item.order_status).text }}
            </v-chip>

            <v-chip
              v-else
              class="font-weight-bold"
              color="red accent-2"
              outlined
              small
            >
              Bị hủy
            </v-chip>
          </td>
        </tr>
        <!-- End: Items -->
      </tbody>

      <tbody v-else>
        <tr class="v-data-table__empty-wrapper">
          <td colspan="10">Không có dữ liệu</td>
        </tr>
      </tbody>
    </template>
    <!-- End: Body -->
  </v-data-table>
</template>

<script>
import { getOrderStatusByValue } from "@/modules/Transaction/utils/common";
import { SALE_RECEIPT_STATUS } from "@/modules/Sale/pages/SaleReceipt/constants";

export default {
  data() {
    return {
      SALE_RECEIPT_STATUS,
      headers: [
        {
          text: "Mã hóa đơn",
          align: "start",
          sortable: false,
          value: "code"
        },
        {
          text: "Thời gian tạo",
          align: "start",
          sortable: false,
          value: "created_at"
        },
        {
          text: "Khách hàng",
          align: "start",
          sortable: false,
          value: "khachhang_name"
        },
        {
          text: "Công nợ KH",
          align: "start",
          sortable: false,
          value: "khachhang_con_no"
        },

        {
          text: "Người chốt đơn",
          align: "start",
          sortable: false,
          value: "closing_user"
        },
        {
          text: "Chi nhánh",
          align: "start",
          sortable: false,
          value: "branch_name"
        },
        {
          text: "Tổng tiền hàng",
          align: "start",
          sortable: false,
          value: "goods_value"
        },
        {
          text: "Khách cần trả",
          align: "start",
          sortable: false,
          value: "khachhang_con_no"
        },
        {
          text: "Khách còn nợ",
          align: "start",
          sortable: false,
          value: "khachhang_con_no"
        },
        {
          text: "Trạng thái",
          align: "center",
          sortable: false,
          value: "status"
        },
        {
          text: "Trạng thái ĐH",
          align: "center",
          sortable: false,
          value: "status_label"
        }
      ]
    };
  },
  computed: {
    saleReceiptListTotals() {
      return this.$store.getters["SALE_RECEIPT/saleReceiptListTotals"];
    },
    saleReceipts() {
      return this.$store.getters["SALE_RECEIPT/saleReceipts"];
    },
    saleReceiptStatusRequest() {
      return this.$store.getters["SALE_RECEIPT/statusRequest"];
    }
  },
  methods: {
    async openModalProduct(product) {
      await this.$store.dispatch("PRODUCT/getProductById", product.id);
      await this.$store.dispatch(
        "PRODUCT_OPTION/getProductOptionsByProductId",
        product.id
      );

      this.$modal.show({
        name: "modal-product"
      });
    },

    getIndexOfSaleReceipt(val) {
      const arr = this.saleReceipts.map(item => item.id);

      return arr.indexOf(val);
    },

    getOrderStatus(value) {
      return getOrderStatusByValue(value);
    },

    async viewDetail(item) {
      await this.$store.dispatch("SALE_RECEIPT/getSaleReceiptById", item.id);

      this.$modal.show({
        name: "modal-sale-receipts",
        payload: {
          index: this.getIndexOfSaleReceipt(item.id)
        }
      });

      this.$store.dispatch(
        "SALE_RECEIPT/getSaleReceiptPaymentTransactions",
        item.id
      );

      this.$store.dispatch(
        "INVOICE_PARTICIPANT/getInvoiceParticipantsOfInvoice",
        {
          modelName: "App\\Models\\HoaDonBan",
          modelId: item.id
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.datatable {
  .v-data-table-header {
    background-color: green;
  }

  .copied-label {
    cursor: pointer;
    display: inline-block;
  }

  .view-label {
    cursor: pointer;
    transition: all 0.4s ease;
  }
}

.w-max-content {
  width: max-content;
}
</style>
